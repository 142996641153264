import Link from "next/link";
import { useEffect } from "react";
import {
  BannerContainer, BannerLeft, BlockQuater, BlockThird, BodyHeading, Button, ButtonContainer, ButtonInverse,
  Card, CardImage, CardTable, CardText, Column, ColumnRow, ColumnRowLeft, ColumnRowRight,
  ContainerContent, Heading, HeadingBoldBg, HeadingText, HomeBanner, Icon, IconWrapper, Image, MainContainer, ShortLine,
  TextCenter, WidePager
} from "../styles/BodyStyle";


export default function MainHome() {

  return (
    <>
      <HomeBanner>
        <BannerContainer>
          <MainContainer>
            <BannerLeft>
              <Heading>Medville Engineering Limited</Heading>
              <HeadingBoldBg>Providing Excellent
                Healthcare Engineering
                Solution</HeadingBoldBg>
              <HeadingText>
                We manage, maintain and restore
                optimum efficiency for medical
                equipment
              </HeadingText>
              <ButtonContainer>
                <Link href="/contact"><Button>Contact Us</Button></Link>
                <Link href="/about"><ButtonInverse>Explore More</ButtonInverse></Link>
              </ButtonContainer>

            </BannerLeft>
          </MainContainer>
        </BannerContainer>

      </HomeBanner>


      <ContainerContent>
        <MainContainer>
          <ColumnRow style={{ alignItems: 'center' }}>
            <ColumnRowLeft style={{ zIndex: 99 }}>
              <CardImage image={`/images/image1.svg`}> </CardImage>
            </ColumnRowLeft>
            <ColumnRowRight>
              <CardText>
                <Column>
                  <BodyHeading>About Us</BodyHeading>
                  <ShortLine></ShortLine>
                </Column>
                <HeadingText>
                  Decades of collective experience across our team has provided us with invaluable insight and understanding of the strict regulatory guidelines and unique operational challenges faced by healthcare organizations and OEMs alike. Now we work in close partnership with our clients to determine their specific needs before delivering the highest possible standards of care through efficient maintenance and management of medical devices and  healthcare engineering services.
                </HeadingText>
                <ButtonContainer>
                  <Link href="/about"><ButtonInverse>Find out more</ButtonInverse></Link>
                </ButtonContainer>
              </CardText>


            </ColumnRowRight>


          </ColumnRow>

        </MainContainer>
      </ContainerContent>
      <WidePager>
        <ContainerContent>
          <MainContainer>
            <Column>
              <Column style={{ alignItems: 'center' }}>
                <BodyHeading>What We Do</BodyHeading>
                <ShortLine></ShortLine>
              </Column>
              <TextCenter style={{ padding: '20px' }}>
                Learn more about how we would adopt a tailored service approach to meet your exact needs. We work in close partnership with our customers to determine their specific needs before delivering the highest possible standards of care through efficient and fully compliant maintenance of medical devices and
                management of healthcare engineering services.
              </TextCenter>
              <CardTable>
                <BlockThird>
                  <Card>
                    <Column>
                      <IconWrapper>
                        <Icon image='/icons/icon1.svg'></Icon>
                      </IconWrapper>
                      <TextCenter>
                        <h3>EQUIPMENT SERVICE & REPAIRS</h3>
                      </TextCenter>
                      <TextCenter>
                        Medville help customers to identify the correct equipment requirements based on the staff/patient capacity and clinical service they provide.
                      </TextCenter>
                    </Column>
                  </Card>
                </BlockThird>
                <BlockThird>
                  <Card>
                    <Column>
                      <IconWrapper>
                        <Icon image='/icons/icon2.svg'></Icon>
                      </IconWrapper>
                      <TextCenter>
                        <h3>MEDICAL EQUIPMENT COVERED</h3>
                      </TextCenter>
                      <TextCenter>
                        Medville help customers to identify the correct equipment requirements based on the staff/patient capacity and clinical service they provide.
                      </TextCenter>
                    </Column>
                  </Card>
                </BlockThird>
                <BlockThird>
                  <Card>
                    <Column>
                      <IconWrapper>
                        <Icon image='/icons/icon3.svg'></Icon>
                      </IconWrapper>
                      <TextCenter>
                        <h3>SPECIALITY SERVICES</h3>
                      </TextCenter>
                      <TextCenter>
                        Medville help customers to identify the correct equipment requirements based on the staff/patient capacity and clinical service they provide.
                      </TextCenter>
                    </Column>
                  </Card>
                </BlockThird>
                <BlockThird>
                  <Card>
                    <Column>
                      <IconWrapper>
                        <Icon image='/icons/icon4.svg'></Icon>
                      </IconWrapper>
                      <TextCenter>
                        <h3>SERVICE CONTRACTS</h3>
                      </TextCenter>
                      <TextCenter>
                        Medville help customers to identify the correct equipment requirements based on the staff/patient capacity and clinical service they provide.
                      </TextCenter>
                    </Column>
                  </Card>
                </BlockThird>
                <BlockThird>
                  <Card>
                    <Column>
                      <IconWrapper>
                        <Icon image='/icons/icon5.svg'></Icon>
                      </IconWrapper>
                      <TextCenter>
                        <h3>ON-SITE CALLIBRATION</h3>
                      </TextCenter>
                      <TextCenter>
                        Medville help customers to identify the correct equipment requirements based on the staff/patient capacity and clinical service they provide.
                      </TextCenter>
                    </Column>
                  </Card>
                </BlockThird>
                <BlockThird>
                  <Card>
                    <Column>
                      <IconWrapper>
                        <Icon image='/icons/icon6.svg'></Icon>
                      </IconWrapper>
                      <TextCenter>
                        <h3>ELECTRICAL SAFETY TESTING</h3>
                      </TextCenter>
                      <TextCenter>
                        Medville help customers to identify the correct equipment requirements based on the staff/patient capacity and clinical service they provide.
                      </TextCenter>
                    </Column>
                  </Card>
                </BlockThird>
              </CardTable>
            </Column>

          </MainContainer>
        </ContainerContent>
      </WidePager>
      <ContainerContent>
        <MainContainer>
          <ColumnRow style={{ alignItems: 'center' }}>
            <ColumnRowLeft style={{ zIndex: 99 }}>
              <CardImage image={`/images/team.svg`}> </CardImage>
            </ColumnRowLeft>
            <ColumnRowRight>
              <CardText>
                <Column>
                  <BodyHeading>Our Team</BodyHeading>
                  <ShortLine></ShortLine>
                </Column>
                <HeadingText>
                  Our passion for healthcare is at the heart of everything we do. We are a multi-cultural team of hard working, dedicated people that come together to support the delivery of excellent patient care.                </HeadingText>
                <ButtonContainer>
                  <Link href="/about"><ButtonInverse>Find out more</ButtonInverse></Link>
                </ButtonContainer>
              </CardText>


            </ColumnRowRight>


          </ColumnRow>

        </MainContainer>
      </ContainerContent>
      <WidePager>
        <ContainerContent>
          <MainContainer>
            <Column>
              <Column style={{ alignItems: 'center' }}>
                <BodyHeading>Products</BodyHeading>
                <ShortLine></ShortLine>
              </Column>
              <TextCenter style={{ padding: '20px' }}>
                Learn more about how we would adopt a tailored service approach to meet your exact needs. We work in close partnership with our customers to determine their specific needs before delivering the highest possible standards of care through efficient and fully compliant maintenance of medical devices and
                management of healthcare engineering services.
              </TextCenter>
              <CardTable>
                <BlockQuater>
                  <Card>
                    <Column> 
                   <Image src={`/images/products/product1.jpg`} alt="product_image" />
                    <TextCenter>
                    ECG Patient Monitor TRITON ELECTRONICS LTD
                      </TextCenter>
                      
                    </Column>
                  </Card>
                </BlockQuater>
                <BlockQuater>
                  <Card>
                    <Column> 
                   <Image src={`/images/products/product2.jpg`} alt="product_image" />
                    <TextCenter>
                    ECG Patient Monitor TRITON ELECTRONICS LTD
                      </TextCenter>
                     
                    </Column>
                  </Card>
                </BlockQuater>
                <BlockQuater>
                  <Card>
                    <Column> 
                   <Image src={`/images/products/product3.jpg`} alt="product_image" />
                    <TextCenter>
                    OMRON BP710N/ 3 Series Advance Accuracy Upper Arm BP Monitor
                      </TextCenter>
                      
                    </Column>
                  </Card>
                </BlockQuater>
                <BlockQuater>
                  <Card>
                    <Column> 
                   <Image src={`/images/products/product4.jpg`} alt="product_image" />
                    <TextCenter>
                    MDF MD One Stainless Steel Dual Head stesthoscope
                      </TextCenter>
                      
                    </Column>
                  </Card>
                </BlockQuater>
              </CardTable> 
              <ButtonContainer style={{justifyContent: 'center'}}>
                  <Link href="/products"><ButtonInverse>View More</ButtonInverse></Link>
                </ButtonContainer>
            </Column>
           
          </MainContainer>
        </ContainerContent>
      </WidePager>
      <ContainerContent>
        <MainContainer>
          <ColumnRow style={{ alignItems: 'center' }}>
            <ColumnRowLeft style={{ zIndex: 99 }}>
              <CardImage image={`/images/projects.svg`}> </CardImage>
            </ColumnRowLeft>
            <ColumnRowRight>
              <CardText>
                <Column>
                  <BodyHeading>Projects</BodyHeading>
                  <ShortLine></ShortLine>
                </Column>
                <HeadingText>
                Our passion for healthcare is at the heart of everything we do. We are a multi-cultural team of hard working, dedicated people that come together to support the delivery of excellent patient care.                   </HeadingText>
                <ButtonContainer>
                  <Link href="/projects"><ButtonInverse>View Our Projects</ButtonInverse></Link>
                </ButtonContainer>
              </CardText>
            </ColumnRowRight>
          </ColumnRow>
        </MainContainer>
      </ContainerContent>

      {/* <ContainerContent>
        <MainContainer>
        <Column>
              <Column style={{ alignItems: 'center' }}>
                <BodyHeading>Testimonial</BodyHeading>
                <ShortLine></ShortLine>
              </Column>
              <TextCenter style={{ padding: '20px' }}>
              Our clients are our raison d’etre. And nothing is more heartwarming to hear the honest comments from our clients.We believe we can works more effecting works to our customer in future.              </TextCenter>
              <CardTable>
                <BlockThird>
                  <Card>
                    <Column> 
                   <Image src="" alt="product_image" />
                    <TextCenter>
                       Testimonial Title
                      </TextCenter>
                      
                    </Column>
                  </Card>
                </BlockThird>
                <BlockThird>
                  <Card>
                    <Column> 
                   <Image src="" alt="product_image" />
                    <TextCenter>
                       Testimonial Title
                      </TextCenter>
                      
                    </Column>
                  </Card>
                </BlockThird>
                <BlockThird>
                  <Card>
                    <Column> 
                   <Image src="" alt="product_image" />
                    <TextCenter>
                       Testimonial Title
                      </TextCenter>
                      
                    </Column>
                  </Card>
                </BlockThird>
              </CardTable> 
            </Column>
        </MainContainer>
      </ContainerContent> */}

    </>
  )
}
