import MainHome from "./home.js";
import Head from 'next/head';

export default function Home() {
  return (
 <>
    <Head>
    <title>Medville Engineering and Services | Home</title>
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <meta property="og:title" content="Medville Engineering and Services | Home" key="title" />
    </Head>
 <MainHome/>
 </>
  )
}
